/*
DUPLICATE STYLE DECLARATIONS BECAUSE OLDER TABLETS DO NOT SUPPORT TAILWIND STYLE CSS GENERATION
*/
.bg-jura-live-green {
  background-color: #6eb95a;
}
.bg-jura-grey-20 {
  background-color: #f5f5f5;
}
.bg-jura-grey-40 {
  background-color: #e1e2e3;
}
.bg-jura-grey-60 {
  background-color: #666d7b;
}
.bg-jura-grey-80 {
  background-color: #575756;
}
.bg-jura-dark-red {
  background-color: #ab000a;
}
.bg-jura-white-choc {
  background-color: #f7f3ea;
}
.bg-jura-brown {
  background-color: #9a7348;
}
.bg-jura-red {
  background-color: #e30613;
}
.bg-white {
  background-color: white;
}

.text-jura-live-green {
  color: #6eb95a;
}
.text-jura-grey-20 {
  color: #f5f5f5;
}
.text-jura-grey-40 {
  color: #e1e2e3;
}
.text-jura-grey-60 {
  color: #666d7b;
}
.text-jura-grey-80 {
  color: #575756;
}
.text-jura-dark-red {
  color: #ab000a;
}
.text-jura-white-choc {
  color: #f7f3ea;
}
.text-jura-brown {
  color: #9a7348;
}
.text-white {
  color: #ffffff;
}
.text-black {
  color: #000000;
}

.border-jura-grey-40 {
  border-color: #e1e2e3;
}
.border-jura-grey-80 {
  border-color: #575756;
}
