@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply font-serif;
  color: red;
}

div {
  @apply select-none;
  @apply font-arial;
}

html.matomoHeatmap {
  height: 1080px;
}
